import _ from 'lodash';
import React from 'react';
import PageHeader from '../PageHeader';
import VideoPlayer from '../VideoPlayer';

import styles from './press.module.scss';

import data from '../../data/press';

const Press = () => {
  const background =
    'https://res.cloudinary.com/dewd4pral/image/upload/q_60/v1497108735/therapistFullBackgroung_lapqmb.jpg';

  const psychCentralImg =
    'https://res.cloudinary.com/dewd4pral/image/upload/v1499200813/psychCentral_wmg71k.jpg';
  const answersImg =
    'https://res.cloudinary.com/dewd4pral/image/upload/v1499201067/answers_sp7kp2.jpg';
  const dhImg = 'https://res.cloudinary.com/dewd4pral/image/upload/v1499201653/dh_vn8ylt.jpg';
  const momClickImg =
    'https://res.cloudinary.com/dewd4pral/image/upload/v1499201204/momClick_lfg3e2.jpg';
  const kslImg = 'https://res.cloudinary.com/dewd4pral/image/upload/v1499201297/ksl_u8at6b.jpg';
  const spartanLifeImg =
    'https://res.cloudinary.com/dewd4pral/image/upload/v1513558973/spartan.jpg';
  const viceImg = 'https://res.cloudinary.com/dewd4pral/image/upload/v1581646906/vice.png';
  const dailyUniverseImg =
    'https://res.cloudinary.com/dewd4pral/image/upload/v1581646432/daily_universe.png';
  const goodRxImg = 'https://res.cloudinary.com/dewd4pral/image/upload/v1634598363/good_rx.png';

  const psychCentralPress = _.map(data.psychCentral, ({ link, title }, index) => {
    return (
      <li key={index}>
        <span>
          <a href={link} target="_blank" rel="noopener noreferrer">
            {title}
          </a>
        </span>
      </li>
    );
  });

  const answersPress = _.map(data.parentingAnswers, ({ link, title }, index) => {
    return (
      <li key={`${index}-${title}`}>
        <span>
          <a href={link} target="_blank" rel="noopener noreferrer">
            {title}
          </a>
        </span>
      </li>
    );
  });

  const momClickPress = _.map(data.momClick, ({ link, title }, index) => {
    return (
      <li key={`${index}-${title}`}>
        <span>
          <a href={link} target="_blank" rel="noopener noreferrer">
            {title}
          </a>
        </span>
      </li>
    );
  });

  const kslPress = _.map(data.ksl, ({ link, title }, index) => {
    return (
      <li key={`${index}-${title}`}>
        <span>
          <a href={link} target="_blank" rel="noopener noreferrer">
            {title}
          </a>
        </span>
      </li>
    );
  });

  const spartanPress = _.map(data.spartanLife, ({ link, title }, index) => {
    return (
      <li key={`${index}-${title}`}>
        <span>
          <a href={link} target="_blank" rel="noopener noreferrer">
            {title}
          </a>
        </span>
      </li>
    );
  });

  const vicePress = _.map(data.vice, ({ link, title }, index) => {
    return (
      <li key={`${index}-${title}`}>
        <span>
          <a href={link} target="_blank" rel="noopener noreferrer">
            {title}
          </a>
        </span>
      </li>
    );
  });

  const dailyUniversePress = _.map(data.dailyUniverse, ({ link, title }, index) => {
    return (
      <li key={`${index}-${title}`}>
        <span>
          <a href={link} target="_blank" rel="noopener noreferrer">
            {title}
          </a>
        </span>
      </li>
    );
  });

  const goodRxPress = _.map(data.goodRx, ({ link, title }, index) => {
    return (
      <li key={`${index}-${title}`}>
        <span>
          <a href={link} target="_blank" rel="noopener noreferrer">
            {title}
          </a>
        </span>
      </li>
    );
  });

  const ocdVideo =
    '<iframe width="100" height="315" src="https://www.youtube.com/embed/hIUkN59EtQo?rel=0" frameborder="0" gesture="media" allow="encrypted-media" allowfullscreen />';

  return (
    <main>
      <PageHeader pageCategory="About" pageName="Press" headerImage={background} />

      <div className={styles.textContainer}>
        <div className={styles.videoContainer}>
          <div className={styles.video}>
            <VideoPlayer videoIframe={ocdVideo} />
          </div>
        </div>

        <h2 className={styles.header}>Press</h2>

        <img
          className={styles.image}
          src={psychCentralImg}
          alt="Mindset Family Therapy on Psych Central"
        />
        <ul>{psychCentralPress}</ul>

        <br />
        <br />
        <img
          className={styles.image}
          src={answersImg}
          alt="Mindset Family Therapy on Answers.com"
        />
        <ul>{answersPress}</ul>

        <br />
        <br />
        <img
          style={{ marginRight: 8 }}
          className={styles.image}
          src={dhImg}
          alt="Mindset Family Therapy on the Daily Herald"
        />
        <img
          className={styles.image}
          src={momClickImg}
          alt="Mindset Family Therapy on MomClick at the Daily Herald"
        />
        <ul>{momClickPress}</ul>

        <br />
        <br />
        <img className={styles.image} src={kslImg} alt="Mindset Family Therapy on KSL.com" />
        <ul>{kslPress}</ul>

        <br />
        <br />
        <img
          className={styles.image}
          src={spartanLifeImg}
          alt="Mindset Family Therapy on Spartan Life"
        />
        <ul>{spartanPress}</ul>

        <br />
        <br />
        <img
          className={styles.image}
          src={viceImg}
          alt="Mindset Family Therapy at The Daily Universe"
        />
        <ul>{vicePress}</ul>

        <br />
        <br />
        <img
          className={styles.image}
          src={dailyUniverseImg}
          alt="Mindset Family Therapy at The Daily Universe"
        />
        <ul>{dailyUniversePress}</ul>

        <br />
        <br />
        <img
          className={styles.image}
          src={goodRxImg}
          alt="Mindset Family Therapy at GoodRx"
        />
        <ul>{goodRxPress}</ul>
      </div>
    </main>
  );
};

export default Press;
