import React from 'react';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Press from '../components/Press';

export default () => (
  <React.Fragment>
    <SEO
      title="Press | Mindset Family Therapy"
      description="Articles and links to other Mindset Family Therapy content."
      pathname="/press"
    />
    <Layout>
      <Press />
    </Layout>
  </React.Fragment>
);
