const press = {
  psychCentral: [
    {
      title: 'Scrupulosity OCD and the Sin of Certainty',
      link:
        'https://psychcentral.com/blog/archives/2017/11/29/scrupulosity-ocd-and-the-sin-of-certainty/',
    },
    {
      title: 'How to Help Anxious Kids Adapt to Change',
      link:
        'https://psychcentral.com/blog/archives/2017/11/05/how-to-help-anxious-kids-adapt-to-change/',
    },
    {
      title: 'Just Right: OCD and Kids',
      link: 'https://psychcentral.com/blog/archives/2017/10/09/just-right-ocd-and-kids/',
    },
    {
      title: 'Comparison: The Perfectionist’s Incessant Urge',
      link:
        'https://psychcentral.com/blog/archives/2017/09/25/comparison-the-perfectionists-incessant-urge/',
    },
    {
      title: 'Pedophilia OCD: When OCD Targets the Children in Your Life',
      link:
        'https://psychcentral.com/blog/archives/2017/09/05/pedophilia-ocd-when-ocd-targets-the-children-in-your-life/',
    },
    {
      title: '7 Essentials for Parents of Kids with OCD',
      link:
        'https://psychcentral.com/blog/archives/2017/08/22/7-essentials-for-parents-of-kids-with-ocd/',
    },
    {
      title: 'Are You Fueling Your OCD?',
      link: 'https://psychcentral.com/blog/archives/2017/06/26/are-you-fueling-your-ocd/',
    },
    {
      title: 'OCD and the Pervasive Reassurance-Seeking Compulsion?',
      link:
        'https://psychcentral.com/blog/archives/2017/06/23/ocd-and-the-pervasive-reassurance-seeking-compulsion/',
    },
    {
      title: 'When Your Kid Is an Athlete and a Perfectionist',
      link:
        'https://psychcentral.com/blog/archives/2017/06/05/when-your-kid-is-an-athlete-and-a-perfectionist/',
    },
    {
      title: 'Got Anxious Kids? Be Brave!',
      link: 'https://psychcentral.com/blog/archives/2017/04/19/got-anxious-kids-be-brave/',
    },
    {
      title: 'Scrupulosity OCD — You Have Choices!',
      link: 'https://psychcentral.com/blog/archives/2017/03/29/scrupulosity-ocd-you-have-choices/',
    },
    {
      title: '4 Essentials To Help You Enjoy the Holidays',
      link:
        'https://psychcentral.com/blog/archives/2016/11/22/4-essentials-to-help-you-enjoy-the-holidays/',
    },
    {
      title: 'OCD: “The Bow that You Have to Keep on Tying”',
      link:
        'https://psychcentral.com/blog/archives/2016/10/09/ocd-the-bow-that-you-have-to-keep-on-tying/',
    },
    {
      title: 'Two Ways to Put the Brakes on Your Anxiety',
      link:
        'https://psychcentral.com/blog/archives/2016/09/23/two-ways-to-put-the-brakes-on-your-anxiety/',
    },
    {
      title: 'OCD, Guilt and Religion',
      link: 'https://psychcentral.com/lib/ocd-guilt-and-religion/',
    },
    {
      title: 'What OCD Feels Like: Being Absolutely Uncertain',
      link: 'https://psychcentral.com/lib/what-ocd-feels-like-being-absolutely-uncertain/',
    },
    {
      title: 'OCD: When Once is Not Enough',
      link: 'https://psychcentral.com/lib/ocd-when-once-is-not-enough/',
    },
    {
      title: 'Healing Those Stubborn Emotional Wounds',
      link: 'https://psychcentral.com/lib/healing-those-stubborn-emotional-wounds/',
    },
    {
      title: 'What’s On Your Plate? 10 Stress Management Strategies',
      link: 'https://psychcentral.com/lib/whats-on-your-plate-stress-management-strategies/',
    },
    {
      title: 'How to Improve Your Mood in a Hurry',
      link: 'https://psychcentral.com/lib/how-to-improve-your-mood-in-a-hurry/',
    },
    {
      title: 'Some Quick Parenting Lessons for the Duchess of Cambridge',
      link:
        'https://psychcentral.com/lib/some-quick-parenting-lessons-for-the-duchess-of-cambridge/',
    },
    {
      title: 'OCD & Perfectionism',
      link: 'https://psychcentral.com/lib/ocd-perfectionism/',
    },
    {
      title: 'Five Telltale Signs Your Child is a Budding Perfectionist',
      link:
        'https://psychcentral.com/lib/five-telltale-signs-your-child-is-a-budding-perfectionist/',
    },
    {
      title: 'Six Ways to Help Your Perfectionist Child Find Balance',
      link: 'https://psychcentral.com/lib/six-ways-to-help-your-perfectionist-child-find-balance/',
    },
    {
      title: 'When OCD Targets Your Relationship',
      link: 'https://psychcentral.com/lib/when-ocd-targets-your-relationship/',
    },
    {
      title: 'Teaching Children to Do Hard Things',
      link: 'https://psychcentral.com/lib/teaching-children-to-do-hard-things/',
    },
    {
      title: 'What Can Parents Do When OCD Sneaks In?',
      link: 'https://psychcentral.com/lib/what-can-parents-do-when-ocd-sneaks-in/',
    },
    {
      title: 'Helping Kids Succeed in School Despite OCD',
      link: 'https://psychcentral.com/lib/helping-kids-succeed-in-school-despite-ocd/',
    },
    {
      title: 'What’s the Best Treatment for Hair-Pulling Disorder?',
      link: 'https://psychcentral.com/lib/whats-the-best-treatment-for-hair-pulling-disorder/',
    },
    {
      title: 'Being Grateful Despite Challenges',
      link: 'https://psychcentral.com/lib/being-grateful-despite-challenges/',
    },
    {
      title: 'What is the Prescription for Raising Entitled Kids?',
      link: 'https://psychcentral.com/lib/what-is-the-prescription-for-raising-entitled-kids/',
    },
    {
      title: 'Scrupulosity: When OCD Targets Your Religious and Moral Values',
      link:
        'https://psychcentral.com/lib/scrupulosity-when-ocd-targets-your-religious-and-moral-values/',
    },
    {
      title: 'Is it OCD, OCPD, or What?',
      link: 'https://psychcentral.com/lib/is-it-ocd-ocpd-or-what/',
    },
    {
      title: 'When Your Loved One Has Body Dysmorphic Disorder',
      link: 'https://psychcentral.com/lib/when-your-loved-one-has-body-dysmorphic-disorder/',
    },
    {
      title: '6 Things You Need to Know Before Starting OCD Treatment',
      link:
        'https://psychcentral.com/blog/archives/2015/06/07/6-things-you-need-to-know-before-starting-ocd-treatment/',
    },
    {
      title: 'Relationship OCD and the Doors of Uncertainty',
      link: 'https://psychcentral.com/lib/relationship-ocd-and-the-doors-of-uncertainty/',
    },
    {
      title: 'Social Anxiety: The Pervasive Creature in your Mind',
      link: 'https://psychcentral.com/lib/social-anxiety-the-pervasive-creature-in-your-mind/',
    },
    {
      title: '6 Ways to Help Your Worried Child',
      link: 'https://psychcentral.com/lib/6-ways-to-help-your-worried-child/',
    },
    {
      title: 'Mindfulness and OCD',
      link: 'https://psychcentral.com/lib/mindfulness-and-ocd/',
    },
    {
      title: 'Coping with Losses Due to COVID-19',
      link: 'https://psychcentral.com/blog/coping-with-losses-due-to-covid-19/',
    },
    {
      title: 'Learning to Embrace Uncertainty',
      link: 'https://psychcentral.com/blog/learning-to-embrace-uncertainty/',
    },
  ],
  parentingAnswers: [
    {
      title: 'Teaching Kids To Be Grateful Every Day',
      link: null,
    },
    {
      title: '3 Steps To Take When Life Gets Foggy',
      link: null,
    },
    {
      title: 'Parenting “Supplies” That Last Forever!',
      link: null,
    },
  ],
  momClick: [
    {
      title: 'Unlocking the castle doors: Raise happy teens through trust',
      link:
        'http://www.heraldextra.com/unlocking-the-castle-doors-raise-happy-teens-through-trust/article_316a0ee6-86a6-5e5c-a02e-adc4adf4ad5f.html',
    },
    {
      title: 'A 3-piece puzzle to fostering life skills',
      link:
        'http://www.heraldextra.com/momclick/parenting/a--piece-puzzle-to-fostering-life-skills/article_544ffbe1-0e10-5940-9317-d664aac1b3e3.html',
    },
    {
      title: 'How to make your parenting vision a reality',
      link:
        'http://www.heraldextra.com/momclick/parenting/how-to-make-your-parenting-vision-a-reality/article_162c0319-06ac-5c54-b998-1b49e541995e.html',
    },
    {
      title: 'Passionate parenting begins with a vision',
      link:
        'http://www.heraldextra.com/momclick/parenting/passionate-parenting-begins-with-a-vision/article_99913269-d53e-5e3e-a477-b9c2512e01da.html',
    },
    {
      title: 'How to bring your children joy',
      link:
        'http://www.heraldextra.com/momclick/parenting/how-to-bring-your-children-joy/article_3c74d744-b94d-5f12-bd82-3173f3008cfa.html',
    },
    {
      title: 'How to help your anxious child be more assertive',
      link:
        'http://www.heraldextra.com/momclick/parenting/how-to-help-your-anxious-child-be-more-assertive/article_48a17494-dd4b-5e67-8135-290f843fc0ea.html',
    },
    {
      title: 'Are you feeling all ‘stwessed’ out?',
      link:
        'http://www.heraldextra.com/momclick/parenting/are-you-feeling-all-stwessed-out/article_d26396bc-3bce-5089-8709-4229dea20dc6.html',
    },
    {
      title: 'Winter blues … need a reboot?',
      link:
        'http://www.heraldextra.com/momclick/health-and-beauty/winter-blues-need-a-reboot/article_5f04b034-70c3-50d6-bb81-df58d5c157ab.html',
    },
    {
      title: '“Will I ever see the end of my rainbow?”',
      link:
        'http://www.heraldextra.com/momclick/parenting/will-i-ever-see-the-end-of-my-rainbow/article_3ec0a208-8678-11e2-8c90-001a4bcf887a.html',
    },
    {
      title: 'It’s the most wonderful time of the year',
      link:
        'http://www.heraldextra.com/momclick/parenting/it-s-the-most-wonderful-time-of-the-year/article_0b0657f0-4618-11e2-bee6-001a4bcf887a.html',
    },
    {
      title: 'What’s on Your Plate? Stress Management Strategies',
      link:
        'http://www.heraldextra.com/momclick/parenting/it-s-the-most-wonderful-time-of-the-year/article_0b0657f0-4618-11e2-bee6-001a4bcf887a.html',
    },
  ],
  ksl: [
    {
      title: 'Boys with untreated ADHD suffer socially, economically later in life',
      link:
        'http://www.ksl.com/index.php?sid=22622576&nid=1010&title=boys-with-untreated-adhd-suffer-socially-economically-later-in-life&s',
    },
  ],
  spartanLife: [
    {
      title: 'Give Your Kids the Best Christmas Ever—No Gifts Required',
      link: 'https://life.spartan.com/post/give-kids-best-christmas-ever-no-gifts-required',
    },
    {
      title: '5 Ways to Teach Your Children to Be Brave',
      link: 'https://life.spartan.com/post/5-ways-to-teach-your-children-to-be-brave',
    },
  ],
  vice: [
    {
      title: 'The Many Obsessions That Can Haunt a Person with OCD',
      link:
        'https://www.vice.com/en_us/article/nex4qm/the-many-obsessions-that-can-haunt-a-person-with-ocd',
    },
  ],
  dailyUniverse: [
    {
      title: 'Religious OCD: When Faith becomes an Obsession',
      link:
        'https://universe.byu.edu/2020/01/29/religious-ocd-when-faith-becomes-an-obsession-rather-than-a-consolation-at-byu/',
    },
    {
      title: 'Coping with Germophobia in a Pandemic',
      link: 'https://universe.byu.edu/2020/05/13/coping-with-germaphobia-in-a-pandemic/',
    },
  ],
  goodRx: [
    {
      title: 'What Is Scrupulosity, and How Is It Treated?',
      link: 'https://www.goodrx.com/conditions/obsessive-compulsive-disorder/what-is-scrupulosity',
    },
  ],
};

export default press;
